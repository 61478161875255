<template>
  <div class="grg_componente_footer">
    <section
      class="grg_section_footer"
      style="background-color: #bd203b"
      :class="{ grg_p_2: !grg_var_view_port_md, grg_p_1: grg_var_view_port_md }"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <h1
                class="grg_text_extra_small grg_font_family_rb m-0 text-white"
              >
                GRG<span style="font-size: 10px">®</span> forma parte de la
                #FamiliaGigante, conoce más de Grupo Gigante<span
                  style="font-size: 10px"
                  >®</span
                >
                <a
                  href="http://www.grupogigante.com.mx/"
                  target="__blank"
                  class="text-white"
                  ><u> aquí.</u></a
                >
              </h1>
            </div>
          </div>
        </div>
        <div class="row justify-content-center align-items-center">
          <div class="col-6">
            <div class="text-center grg_container_legales">
              <div class="text-left">
                <h1
                  class="
                    position-relative
                    grg_text_extra_small
                    text-uppercase
                    grg_font_family_rb
                  "
                >
                  <router-link to="/vacantes" class="text-white"
                    ><u>Trabaja con Nosotros</u></router-link
                  >
                </h1>
                <h1
                  class="
                    position-relative
                    grg_text_extra_small
                    text-uppercase
                    grg_font_family_rb
                  "
                >
                  <router-link to="/preguntasFrecuentes" class="text-white"
                    ><u>Preguntas frecuentes</u></router-link
                  >
                </h1>
                <h1
                  class="
                    position-relative
                    grg_text_extra_small
                    text-uppercase
                    grg_font_family_rb
                  "
                >
                  <a class="text-white" target="_blank" :href="obtenerArchivosServidor('grg-admin/grg_pdf/Aviso de privacidad.pdf')"><u>Privacidad de datos</u></a>
                </h1>
                <h1
                class="
                  position-relative
                  grg_text_extra_small
                  text-uppercase
                  grg_font_family_rb
                "
              >
                <router-link to="/lineaetica" class="text-white"
                  ><u> Línea Ética</u></router-link
                >
              </h1>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="text-center">
              <img
                style="margin-left: 6%"
                width="200px"
                class="img-fluid"
                src="@/assets/img/logos/logo_footer.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <h1
              class="
                grg_text_extra_small
                text-uppercase
                grg_font_family_rb
                m-0
                text-white
              "
            >
              © Todos los derechos reservados grupo restaurantero gigante ® 2021
            </h1>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapState([
      "grg_var_scroll",
      "grg_var_view_port_md",
      "grg_var_transparencia",
    ]),
  },
  methods:{
    obtenerArchivosServidor(ruta) {
      if (ruta != "") {
        return this.$baseURL + ruta;
        // return require('@/assets/img/slider/'+imagen)
      }
    },
  },
};
</script>

<style scoped>
.grg_p_2 {
  padding: 1.5rem 0;
}
.grg_p_1 {
  padding: 0.5rem 0;
}
.grg_container_legales {
  display: flex;
  justify-content: center;
}

@media (min-width: 1200px) {
}
@media (min-width: 992px) {
}
@media (min-width: 768px) {
}
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {
  .grg_container_legales .grg_text_extra_small {
    font-size: calc(0.5rem + 0.5vw + -1px);
  }
}
@media (max-width: 575.98px) {
}
</style>
