<template>
  <div class="grg_page_home" id="grg_page_home">
    <img id="myImg" src="@/assets/img/utilerias/PE_GG60_1920x1080.webp" style="width:100%;max-width:300px; display:none " />
    <div id="myModal" class="modal">
        <span class="closeModal">&times;</span>
        <a href="https://cuponera.itn.do/auth/signup"><img class="modal-content" id="img01"></a>
        <div id="caption"></div>
    </div>
    <section
      class="grg_section_principal"
      @click="reproducir()"
      @dblclick="reiniciar()"
    >
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-12 p-0 h-100 grg_evitar_seleccion"
            style="line-height: 0"
          >
            <video
              id="grg_video_promocional"
              class=" w-100 h-100"
              poster="@/assets/img/utilerias/imagen_portada_video1.webp"
              :class="{ grg_transparencia: show }"
            >
              <source :src="$baseURL+'grg-admin/grg_videos/portada_grg.mp4'" type="video/mp4" />
              <!-- <source
                src="http://pruebas2020menudigi.000webhostapp.com/produccion/media/RestauranteSA.mp4"
                type="video/mp4"
              /> -->
              Tu navegador no soporta HTML5 video.
            </video>
            <div class="grg_container_titulo_pagina">
              <transition name="fade">
                <h1
                  v-if="!video_activo"
                  class="
                    grg_font_outline grg_font_family_rb grg_title_bigger
                    text-uppercase
                  "
                  style="
                    --color_solid: #fff;
                    --color_outline: #fff;
                    --pos_x: 0.05em;
                    --pos_y: -0.05em;
                  "
                >
                  Bienvenidos
                </h1>
              </transition>
            </div>
            <div class="grg_container_boton_play">
              <transition name="fade">
                <img
                  v-if="!video_activo"
                  width="10%"
                  class="img-fluid"
                  src="@/assets/img/utilerias/play.svg"
                  alt=""
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_marcas">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-sm-6 col-md-4 col-lg-4 col-xl-4 p-0 grg_container_color"
            id="grg_width_marcas"
            style="--background_color: #b3a292"
          >
            <div
              class="
                h-100
                w-100
                border border-white
                d-flex
                grg_container_titulo_section
              "
            >
              <div class="grg_container_titulo">
                <h1
                  class="
                    grg_font_outline grg_font_family_reb grg_title_medium
                    text-uppercase
                    position-relative
                  "
                  style="
                    --color_solid: #fff;
                    --color_outline: #fff;
                    --pos_x: 0.05em;
                    --pos_y: -0.05em;
                    line-height: 1;
                  "
                  data-utilerias="flechas_blancas"
                >
                  Conoce nuestras marcas
                </h1>
              </div>
              <div class="grg_container_hover grg_container_subtitulo">
                <router-link to="/nuestras-marcas"
                  ><h1
                    class="
                      grg_font_family_reb
                      grg_text_medium
                      grg_btn
                      grg_btn_animacion
                    "
                    style="--color: #b3a292; --background_color: #fff"
                    id="nuestras_marcas_modelo"
                    data-text="Nuestras Marcas"
                    data-btn="transparente"
                  >
                    Nuestras Marcas
                  </h1></router-link
                >
              </div>
            </div>
          </div>
          <div
            v-for="platillo of platillos"
            :key="platillo.id"
            class="
              col-sm-6 col-md-4 col-lg-4 col-xl-4
              p-0
              grg_container_platillo
            "
          >
            <div class="h-100 w-100 border border-white position-relative">
              <img
                class="img-fluid grg_img_platillo w-100 h-100"
                :style="{
                  'object-position': platillo.posicion_imagen_platillo,
                }"
                :src="obtenerImagenesPlatillo(platillo.imagen)"
                alt=""
              />
              <div class="grg_container_imagen_logo">
                <img
                  :width="platillo.width_logo + '%'"
                  :style="{
                    '--hover_width_logo': platillo.width_logo + 10 + '%',
                  }"
                  class="img-fluid grg_img_logo"
                  :src="obtenerLogosMarcas(platillo.logo)"
                  alt=""
                />
              </div>
              <div class="grg_container_leyenda">
                <div>
                  <div class="grg_container_hover">
                    <router-link
                      :to="{
                        path: '/nuestras-marcas',
                        hash: '' + platillo.id_contenedor + '',
                      }"
                      ><h2
                        class="
                          grg_font_family_rb
                          grg_text_extra_small
                          grg_btn
                          grg_btn_animacion
                        "
                        style="--color: #fff; --background_color: #fff"
                        data-text="Conoce más >>"
                        data-btn="transparente"
                      >
                        Conoce más >>
                      </h2></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_quienes_somos">
      <div class="container-fluid">
        <div class="row">
          <div
            class="
              col-sm-12 col-md-4 col-lg-4 col-xl-4
              p-0
              grg_container_color
              order-lg-2
            "
            style="--background_color: #be2039"
          >
            <div
              class="
                h-100
                w-100
                border border-white
                d-flex
                grg_container_titulo_section
              "
            >
              <div
                class="grg_container_titulo"
                data-container="grg_container_titulo_unico"
              >
                <h1
                  class="
                    grg_font_outline grg_font_family_reb grg_title_medium
                    text-uppercase
                    position-relative
                  "
                  style="
                    --color_solid: #fff;
                    --color_outline: #fff;
                    --pos_x: 0.05em;
                    --pos_y: -0.05em;
                    line-height: 1;
                  "
                >
                  ¿Quiénes Somos?
                </h1>
              </div>
              <div class="grg_container_hover">
                <router-link to="/quienes-somos"
                  ><h1
                    class="
                      grg_font_family_reb
                      grg_text_medium
                      grg_btn
                      grg_btn_animacion
                      grg_elemento_subtitulo
                    "
                    style="--color: #be2039; --background_color: #fff"
                    data-text="Ver más"
                    data-btn="transparente"
                  >
                    Ver más
                  </h1></router-link
                >
              </div>
            </div>
          </div>
          <div
            class="
              col-sm-12 col-md-8 col-lg-8 col-xl-8
              p-0
              border border-white
              order-lg-1
            "
          >
            <img
              class="img-fluid"
              src="@/assets/img/home/nuestra_historia_nuevo.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_responsabilidad_social">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-sm-12 col-md-4 col-lg-4 col-xl-4 p-0 grg_container_color"
            style="--background_color: #ffca00"
          >
            <div
              class="
                h-100
                w-100
                border border-white
                d-flex
                grg_container_titulo_section
              "
            >
              <div
                class="grg_container_titulo"
                data-container="grg_container_titulo_unico"
              >
                <h1
                  class="
                    grg_font_outline grg_font_family_reb grg_title_medium
                    text-uppercase
                    position-relative
                  "
                  style="
                    --color_solid: #fff;
                    --color_outline: #fff;
                    --pos_x: 0.05em;
                    --pos_y: -0.05em;
                    line-height: 1;
                  "
                >
                  <span>Responsabilidad</span> social
                </h1>
              </div>
              <div class="grg_container_hover">
                <router-link to="/responsabilidad-social"
                  ><h1
                    class="
                      grg_font_family_reb
                      grg_text_medium
                      grg_btn
                      grg_btn_animacion
                      grg_elemento_subtitulo
                    "
                    style="--color: #ffca00; --background_color: #fff"
                    data-text="Ver más"
                    data-btn="transparente"
                  >
                    Ver más
                  </h1></router-link
                >
              </div>
            </div>
          </div>
          <div
            class="
              col-sm-12 col-md-8 col-lg-8 col-xl-8
              p-0
              border border-white
              order-lg-1
            "
          >
            <Slider :sliders="sliders"></Slider>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_a_comer_club">
      <div class="container-fluid">
        <div class="row">
          <div
            class="
              col-sm-12 col-md-4 col-lg-4 col-xl-4
              p-0
              order-lg-2
              grg_container_color
            "
            style="--background_color: #b3a292"
          >
            <div
              class="
                h-100
                w-100
                border border-white
                d-flex
                grg_container_titulo_section
              "
            >
              <div
                class="grg_container_titulo"
                data-container="grg_container_titulo_unico"
              >
                <h1
                  class="
                    grg_font_outline grg_font_family_reb grg_title_medium
                    text-uppercase
                    position-relative
                  "
                  style="
                    --color_solid: #fff;
                    --color_outline: #fff;
                    --pos_x: 0.05em;
                    --pos_y: -0.05em;
                    line-height: 1;
                  "
                >
                  Nuestra gente
                </h1>
              </div>
              <div class="grg_container_hover">
                <router-link to="/nuestra-gente"
                  ><h1
                    class="
                      grg_font_family_reb
                      grg_text_medium
                      grg_btn
                      grg_btn_animacion
                      grg_elemento_subtitulo
                    "
                    style="--color: #b3a292; --background_color: #fff"
                    data-text="Ver más"
                    data-btn="transparente"
                  >
                    Ver más
                  </h1></router-link
                >
              </div>
            </div>
          </div>
          <div
            class="
              col-sm-12 col-md-8 col-lg-8 col-xl-8
              p-0
              border border-white
              order-lg-1
            "
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
                  <img
                    class="img-fluid grg_img_a_comer w-100 h-100"
                    src="@/assets/img/home/acomerclub.webp"
                    alt=""
                  />
                </div>
                <div
                  class="
                    col-sm-6 col-md-6 col-lg-6 col-xl-6
                    grg_container_a_comer_club
                  "
                >
                  <div class="w-100">
                    <img
                      class="img-fluid grg_img_logo_a_comer"
                      width="70%"
                      src="@/assets/img/logos/a_comer_club.svg"
                      alt=""
                    />
                  </div>
                  <div
                    data-utilerias="patrones"
                    class="
                      w-100
                      grg_container_leyenda_a_comer
                      position-relative
                    "
                  >
                    <h1
                      class="
                        grg_font_outline
                        grg_title_extra_small
                        grg_font_family_reb
                        position-relative
                      "
                      style="
                        --color_solid: #000;
                        --color_outline: #000;
                        --pos_x: 0.05em;
                        --pos_y: -0.05em;
                        line-height: ;
                      "
                    >
                      El punto<br />es que vuelvas.
                    </h1>
                  </div>
                  <div class="grg_container_hover">
                    <a target="_blank" href="https://www.acomerclub.com.mx/"
                      ><h1
                        class="
                          grg_font_family_reb
                          grg_text_medium
                          grg_btn
                          grg_btn_animacion
                        "
                        style="--color: #fff; --background_color: #ff595b"
                        data-text="Programa de Lealtad"
                        data-btn="rojo"
                      >
                        Programa de lealtad
                      </h1></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_numeralia">
      <div class="container-fluid">
        <div class="row">
          <div
            class="
              col-sm-12 col-md-12 col-lg-12 col-xl-12
              p-0
              border border-white
              grg_container_color
            "
            style="--background_color: #2b2b2b"
          >
            <div class="grg_container_numeralia">
              <div>
                <img
                  class="img-fluid grg_img_logo_numeralia"
                  src="@/assets/img/logos/logo_numeralia.svg"
                  alt=""
                />
              </div>
              <div>
                <h1
                  class="
                    grg_font_outline grg_font_family_rb
                    text-uppercase
                    grg_title_small
                  "
                  style="
                    --color_solid: transparent;
                    --color_outline: #fff;
                    --pos_x: 0em;
                    --pos_y: 0em;
                  "
                >
                  En números
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_modulo_datos_numeros">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-12 p-0 border border-white grg_container_color"
            style="--background_color: #fff"
          >
            <div class="grg_container_slider">
              <div class="grg_conatiner_slider_comodin">
                <div id="grg_slider_datos" class="grg_slider">
                  <section
                    v-for="numeralia of numeralias"
                    :key="numeralia.id"
                    class="slider-section"
                  >
                    <div>
                      <img
                        class=""
                        :width="numeralia.icono_width"
                        :src="obtenerIconosNumeralia(numeralia.icono)"
                        alt=""
                      />
                    </div>
                    <div>
                      <div
                        class="
                          grg_font_outline grg_font_family_reb
                          text-uppercase
                          grg_container_numeralia_h1
                        "
                        style="
                          --color_solid: #000;
                          --color_outline: #000;
                          --pos_x: 0.12em;
                          --pos_y: 0.12em;
                          line-height: ;
                        "
                        v-html="numeralia.nombre"
                      ></div>
                    </div>
                  </section>
                </div>
              </div>
            </div>

            <div @click="moverSliderIzquierda" class="grg_boton_anterior">
              <img
                width="40px"
                src="@/assets/img/utilerias/flechas_negras_izquierda.svg"
                alt=""
              />
            </div>
            <div @click="moverSliderDerecha" class="grg_boton_siguiente">
              <img
                width="40px"
                src="@/assets/img/utilerias/flechas_negras_derecha.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Slider from "@/components/Slider.vue";
export default {
  name: "Home",
  components: {
    Slider,
  },
  data() {
    return {
      userData: {
        name: "Alejandro Romero",
        username: "maraleronava",
        tagline: "nose",
        profileurl: "Alejandro",
      },
      video_activo: false,
      show: false,
      platillos: [
        // {
        //   id: 1,
        //   id_contenedor: 'toks',
        //   imagen: "toks.jpg",
        //   position_imagen_platillo:'center',
        //   logo: "toks.png",
        //   width_logo: 25,
        //   link_web: "https://www.toks.com.mx/" },
        // {
        //   id: 2,
        //   id_contenedor: 'panda_express',
        //   imagen: "panda_express.jpg",
        //   position_imagen_platillo:'center',
        //   logo: "panda_express.svg",
        //   width_logo: 20,
        //   link_web: "https://www.pandaexpress.com.mx/es/" },
        // {
        //   id: 3,
        //   id_contenedor: 'beer_factory',
        //   imagen: "beer_factory.jpg",
        //   position_imagen_platillo:'center',
        //   logo: "beer_factory.png",
        //   width_logo: 65,
        //   link_web: "https://www.beerfactory.com.mx/" },
        // {
        //   id: 4,
        //   id_contenedor: 'shake_shack',
        //   imagen: "shake_shack.jpg",
        //   position_imagen_platillo:'right',
        //   logo: "shake_shack.svg",
        //   width_logo: 50,
        //   link_web: "https://www.shakeshack.com.mx/" },
        // {
        //   id: 5,
        //   id_contenedor: 'el_farolito',
        //   imagen: "el_farolito.jpg",
        //   position_imagen_platillo:'center',
        //   logo: "el_farolito.svg",
        //   width_logo: 50,
        //   link_web: "https://elfarolito.com.mx/" },
      ],
      sliders: [
        {
          id: 1,
          posicion: 0,
          nombre: "Cafe",
          imagen: "/grg-admin/grg_sliders/slider1.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 2,
          posicion: 1,
          nombre: "Reunion",
          imagen: "/grg-admin/grg_sliders/slider2.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 3,
          posicion: 2,
          nombre: "Abejas",
          imagen: "/grg-admin/grg_sliders/slider3.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 4,
          posicion: 3,
          nombre: "Cafe",
          imagen: "/grg-admin/grg_sliders/slider4.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 5,
          posicion: 4,
          nombre: "Reunion",
          imagen: "/grg-admin/grg_sliders/slider5.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
        {
          id: 6,
          posicion: 5,
          nombre: "Abejas",
          imagen: "/grg-admin/grg_sliders/slider6.jpg",
          logo: "",
          width_logo: "",
          descripcion: "",
        },
      ],
      numeralias: [
        {
          id: 1,
          nombre:
            '<h1 class="grg_title_extra_big" style="line-height: 1;">+250</h1><h1 class="grg_text_bigger">unidades de</h1><h1 class="grg_text_bigger">negocio</h1>',
          icono: "numeralia_home1.svg",
          icono_width: "120px",
        },
        {
          id: 2,
          nombre:
            '<h1 class="grg_title_extra_big" style="line-height: 1;">+11,000</h1><h1 class="grg_text_bigger">Colaboradores</h1>',
          icono: "numeralia_home2.svg",
          icono_width: "85px",
        },
        {
          id: 3,
          nombre:
            '<h1 class="grg_text_big">Presencia en</h1><h1 class="grg_title_extra_big" style="line-height: .7;">22</h1><h1 class="grg_text_bigger">Estados</h1><h1 class="grg_text_big">de la república mexicana</h1>',
          icono: "numeralia_home3.svg",
          icono_width: "80px",
        },
        {
          id: 4,
          nombre:
            '<h1 class="grg_title_extra_big" style="line-height: 1;">5</h1><h1 class="grg_text_bigger">Marcas</h1>',
          icono: "numeralia_home4.svg",
          icono_width: "100px",
        },
        {
          id: 5,
          nombre:
            '<h1 class="grg_title_extra_big" style="line-height: 1;">50</h1><h1 class="grg_text_bigger">años de</h1><h1 class="grg_text_bigger">trayectoria</h1>',
          icono: "numeralia_home5.svg",
          icono_width: "90px",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["grgFuncionChangeTransparencia"]),
    reproducir() {
      var video = document.getElementById("grg_video_promocional");
      if (this.video_activo) {
        video.pause();
        this.video_activo = false;
        this.show = true;
      } else {
        video.play();
        this.video_activo = true;
        this.show = false;
      }
    },
    reiniciar() {
      var video = document.getElementById("grg_video_promocional");
      this.show = false;
      this.video_activo = true;
      video.load();
      video.play();
    },
    ajustarTamaño() {
      var widthMarcas = document.getElementById("grg_width_marcas");
      var widthBoton = document.getElementById("nuestras_marcas_modelo");
      var pageMarcas = document.getElementById("grg_page_home");
      pageMarcas.setAttribute(
        "style",
        "--width_boton: " +
          widthBoton.clientWidth +
          "px;--height_marcas: " +
          widthMarcas.clientWidth +
          "px;"
      );
    },
    obtenerImagenesPlatillo(imagen) {
      if (imagen != "") {
        return this.$baseURL + imagen;
      }
    },
    obtenerLogosMarcas(imagen) {
      if (imagen != "") {
        return this.$baseURL + imagen;
      }
    },

    // obtenerImagenesPlatillo(imagen){
    //   if(imagen != ''){
    //     return require('@/assets/img/platillos_home/'+imagen)
    //   }
    // },
    // obtenerLogosMarcas(imagen){
    //   if(imagen != ''){
    //     return require('@/assets/img/logos/'+imagen)
    //   }
    // },

    obtenerIconosNumeralia(icono) {
      if (icono != "") {
        return require("@/assets/img/utilerias/" + icono);
      }
    },
    moverSliderDerecha() {
      $("#grg_slider_datos .slider-section:first").insertAfter(
        "#grg_slider_datos .slider-section:last"
      );
    },
    moverSliderIzquierda() {
      $("#grg_slider_datos .slider-section:last").insertBefore(
        "#grg_slider_datos .slider-section:first"
      );
    },

    activarSlider() {
      $("#grg_slider_nuestras_marcas").carousel();
    },
    obtenerPlatillosApi() {
      const baseURI =
        this.$baseURL + "grg-admin/index.php/platillo/get_platillos_front";
      this.$http
        .get(
          baseURI,
          { params: { token: localStorage.getItem("token") } },
          { "Access-Control-Allow-Origin": "*" }
        )
        .then((res) => {
          // console.log(res.data.platillos);
          this.platillos = res.data.platillos;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    detectarScroll(evt, el) {
      if (window.scrollY > 400) {
        var video = document.getElementById("grg_video_promocional");
        if (this.video_activo) {
          video.pause();
          this.video_activo = false;
          this.show = true;
        }
      }
    },
  },

  created() {
    window.addEventListener("resize", this.ajustarTamaño);
    window.addEventListener("scroll", this.detectarScroll);
    this.grgFuncionChangeTransparencia(false);
  },
  mounted() {
    this.ajustarTamaño();
    this.activarSlider();
    this.obtenerPlatillosApi();
    $("#grg_slider_datos .slider-section:last").insertBefore(
      "#grg_slider_datos .slider-section:first"
    );
    var slider = $("#grg_slider_datos");
    slider.css("margin-left", "-" + 100 + "%");
  },
  computed: {
    ...mapState([
      "grg_var_scroll",
      "grg_var_view_port_md",
      "grg_var_transparencia",
    ]),
  },
  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler(to, from) {
  //       document.title = to.meta.title || "Grupo Restaurantero Gigante";
  //     },
  //   },
  // },
  metaInfo() {
    return {
      // title: `Grupo Restaurantero Gigante ${this.userData.name}`,
      title: `Grupo Restaurantero Gigante`,
      meta: [
        {
          name: "description",
          content: "Grupo Restaurantero Gigante (GRG) lleva las operaciones de Restaurantes Toks, Shake Shack, Beer Factory, El Farolito y Panda Express, restaurantes que seguramente has visitado."
        },
        // { property: "og:title", content: this.userData.name + " - Epiloge" },
        { property: "og:title", content: "Grupo Restaurantero Gigante" },
        { property: "og:site_name", content: "http://gruporestauranterogigante.com.mx/" },
        {
          property: "og:description",
          content: "Este grupo empresarial no solo se encarga de servir alimentos y bebidas, también cumple las expectativas de los clientes contribuyendo a un mundo más sustentable e incluyente con acciones que aportan a los principios del Pacto Mundial y a los Objetivos de Desarrollo Sostenible (ODS).",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://www.facebook.com/GrupoToks",
        },
        {
          property: "og:image",
          content:
            this.$baseURL + "grg-admin/grg_sliders/slider3.jpg",
        },
      ],
    };
  },
};
window.addEventListener('load', function () {
    // Get the modal
    var modal = document.getElementById("myModal");

    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("closeModal")[0];
    // When the user clicks on <span> (x), close the modal
    // span.onclick = function() { 
    //     modal.style.display = "none";
    // }
    // img.onclick = function(){
    //     modal.style.display = "block";
    //     modalImg.src = this.src;
    // }
    // img.onclick();
}, false);
</script>



<style scoped>

.row {
    margin: 0 -15px;
}
video[poster] {
  object-fit: cover;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Estilo para modulo slider datos */
.grg_container_slider {
  margin: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
}
.grg_conatiner_slider_comodin {
  width: 100%;
  margin: 0 auto;
}
.grg_slider {
  display: flex;
  /* modificar el width en caso de agregar nueva numeralia */
  width: 250%;
  /* overflow: hidden; */
}
.slider-section {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: right;
  align-items: center;
}
.slider-section div {
  margin: 0 0.2rem;
}
.grg_boton_anterior,
.grg_boton_siguiente {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.grg_boton_anterior {
  left: 5%;
}
.grg_boton_siguiente {
  right: 5%;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Estilo para modulo slider datos */

/* Estilo para seccion Video principal  */
.grg_container_titulo_pagina {
  position: absolute;
  bottom: 0;
  margin: 0 2% 15vh;
}
.grg_container_boton_play {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.grg_transparencia {
  filter: brightness(0.5);
}

/* Estilo para seccion Video principal  */

/* Estilo para seccion Numeralia  */
.grg_container_numeralia {
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grg_container_numeralia div {
  margin: 0 1rem;
}
.grg_img_logo_numeralia {
  width: 100%;
  max-width: 200px;
}
.grg_section_numeralia h1 {
  margin-bottom: -5%;
}
/* Estilo para seccion Numeralia  */

.grg_container_platillo {
  height: var(--height_marcas);
}
.grg_elemento_subtitulo {
  width: var(--width_boton);
  text-align: center;
}
.grg_img_platillo {
  object-fit: cover;
}
.grg_container_titulo_section {
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}
.grg_container_titulo {
  width: min-content;
}
.grg_container_imagen_logo {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 15%,
    rgba(255, 255, 255, 0)
  );
  position: absolute;
  bottom: 0;
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.3s linear;
}
.grg_container_platillo:hover .grg_container_imagen_logo {
  height: 100%;
}
.grg_img_logo {
  transition: width 0.3s linear;
}
.grg_container_platillo:hover .grg_img_logo {
  width: var(--hover_width_logo);
  transform: translateY(-20px);
}
.grg_container_leyenda {
  position: absolute;
  bottom: 15%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.grg_container_leyenda > div {
  display: none;
}
.grg_container_platillo:hover .grg_container_leyenda > div {
  display: block;
}
.grg_container_a_comer_club {
  display: flex;
  justify-content: space-around;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 960px) {
  .grg_section_responsabilidad_social h1 span {
    font-size: calc(1.2rem + (11vw / 11)) !important;
  }
  /* .grg_section_responsabilidad_social .container-fluid .row:first-child{
    flex-direction: row-reverse;
  } */
}

@media (min-width: 1200px) {
  /* Estilo especial para palabra responsabilidad */
  .grg_section_responsabilidad_social h1 span {
    font-size: calc(1.5rem + (11vw / 11)) !important;
  }
}
@media (min-width: 992px) {
  /* Estilo especial para palabra responsabilidad */
  .grg_section_responsabilidad_social h1 span {
    font-size: calc(1rem + (11vw / 10));
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Estilo para modulo slider datos */
  .slider-section div {
    text-align: left;
    margin-right: 10px;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Estilo para modulo slider datos */
}
@media (min-width: 768px) {
  .grg_container_leyenda_a_comer {
    transform: translateY(-20%);
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Estilo para modulo slider datos */
  .slider-section:nth-child(odd) {
    padding: 0 0 0 9.5%;
  }
  .slider-section:nth-child(even) {
    padding: 0 3% 0 0;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Estilo para modulo slider datos */
}
@media (min-width: 576px) {
  .grg_container_titulo,
  .grg_container_subtitulo {
    transform: translateX(-3%);
    text-align: left;
    margin-top: 11%;
  }
  [data-utilerias="flechas_blancas"]:before {
    content: url("../assets/img/utilerias/flechas_blancas.webp");
    position: absolute;
    right: -30px;
    top: 30%;
  }
  [data-utilerias="patrones"]:before {
    content: url("../assets/img/utilerias/patrones.webp");
    position: absolute;
    left: -30%;
    bottom: -40%;
    transform: scale(0.65);
  }
  .grg_container_a_comer_club > div:nth-child(2),
  .grg_container_a_comer_club > div:nth-child(1) {
    text-align: left;
  }
}

@media (max-width: 1199.98px) {
  .grg_container_titulo_pagina {
    position: absolute;
    bottom: 0;
    margin: 0 2%;
  }
}
@media (max-width: 991.98px) {
  
  [data-container="grg_container_titulo_unico"] {
    width: 100%;
    text-align: center;
    transform: translateX(0%);
    margin-top: 0;
    padding: 0 0 1rem;
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Estilo para modulo slider datos */
  .grg_conatiner_slider_comodin {
    width: 100%;
  }
  .slider-section {
    flex-direction: column;
  }

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Estilo para modulo slider datos */
}
@media (max-width: 767.98px) {
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Estilo para modulo slider datos */
  .grg_slider {
    width: 500% !important;
    margin-left: 0 !important;
  }
  .slider-section img {
    width: 80px;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Estilo para modulo slider datos */
}
@media (max-width: 575.98px) {
  
  .grg_container_titulo {
    width: 100%;
    text-align: center;
    padding: 0 0 1rem;
  }

  /* Estilo seccion a comer club */
  .grg_img_a_comer {
    max-width: 270px;
    padding: 0 0 1rem;
  }
  .grg_img_logo_a_comer {
    width: 60%;
  }
  .grg_container_leyenda_a_comer {
    padding: 1rem 0 0rem;
  }
  .grg_section_a_comer_club .grg_container_hover {
    padding: 0 0 1rem;
  }
  /* Estilo seccion a comer club */

  /* Estilo para seccion Numeralia  */
  .grg_container_numeralia div {
    margin: 0 0.5rem;
  }
  .grg_container_numeralia {
    padding: 1rem 0rem;
  }
  .grg_img_logo_numeralia {
    max-width: 120px;
  }
  .grg_section_numeralia h1 {
    margin-bottom: -6%;
  }
  /* Estilo para seccion Numeralia  */

  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Estilo para modulo slider datos */
  .slider-section img {
    width: 60px;
  }
  /*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Estilo para modulo slider datos */
}

#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {opacity: 0.7;}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1040; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content, #caption {  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}

/* The Close Button */
.closeModal {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.closeModal:hover,
.closeModal:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}
</style>