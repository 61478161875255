<template>
  <div class="grg_componente_nav sticky-top">
    <section
      class="
        grg_modulo_header
        navbar navbar-expand-md navbar-light
        grg_container_color
      "
      :class="{
        grg_container_color_transparente: trasparente,
        grg_container_color_scroll: grg_var_scroll,
      }"
      :style="{
        '--background_color': color_rgb,
        '--background_color_transparente': color_rgb + '66',
      }"
    >
      <div class="grg_container_redes" v-if="!grg_var_view_port_md">
        <span class="grg_font_family_rb grg_text_extra_small text-white"
          >¡Síguenos!</span
        >
        <ul>
          <li v-for="red_social of redesSocialesActivas" :key="red_social.id">
            <a target="_blank" :href="red_social.link">
              <img
                class="img-fluid"
                :src="obtenerIconoRedSocial(red_social.icono)"
                alt=""
              />
            </a>
          </li>
        </ul>
      </div>
      <div class="grg_container_logo">
        <router-link to="/"
          ><img
            class="img-fluid grg_img_logo"
            id="grg_img_logo_header"
            :class="{ grg_img_logo_scroll: grg_var_scroll }"
            src="@/assets/img/logos/grg.svg"
            alt=""
        /></router-link>
      </div>
      <button @click="mostrarMenu()" class="navbar-toggler" type="button">
        <img
          class="navbar-toggler-icon"
          src="@/assets/img/utilerias/toggler.svg"
          alt=""
        />
      </button>
    </section>

    <section
      class="grg_modulo_menu grg_container_color"
      style="--background_color: #fff"
    >
      <div
        class="grg_container_menu_uno h-100"
        v-if="!grg_var_view_port_md"
        @click="mostrarMenu()"
      >
        <transition name="fade">
          <h1
            class="
              grg_font_outline grg_font_family_rb
              text-uppercase
              grg_text_bigger
            "
            v-if="!show"
            data-flechas="vertical"
            style="
              --color_solid: #bc2038;
              --color_outline: #bc2038;
              --pos_x: 0.05em;
              --pos_y: 0.05em;
            "
          >
            Menú
          </h1>
        </transition>
      </div>
      <div
        class="grg_container_menu_dos grg_container_color"
        :class="{ grg_show_menu: show }"
        style="--background_color: #fff"
      >
        <div class="navbar-light navbar">
          <button
            @click="mostrarMenu()"
            class="navbar-toggler"
            type="button"
            v-if="grg_var_view_port_md"
          >
            <img
              class="navbar-toggler-icon"
              src="@/assets/img/utilerias/cerrar.svg"
              alt=""
            />
          </button>
          <div class="d-flex justify-content-center w-100">
            <h1
              class="
                grg_font_outline grg_font_family_rb
                text-uppercase
                grg_text_bigger
                m-0
                grg_p_1
              "
              @click="mostrarMenu()"
              data-flechas="horizontal"
              style="
                --color_solid: #bc2038;
                --color_outline: #bc2038;
                --pos_x: 0.05em;
                --pos_y: 0.05em;
              "
            >
              Menú
            </h1>
          </div>
        </div>
        <router-link
          @click.native="mostrarMenu()"
          v-for="item of itemsMenuActivos"
          :key="item.id"
          :to="item.link"
        >
          <div class="grg_container_item_hover">
            <div class="grg_container_item">
              <h1
                class="grg_font_family_reb grg_text_bigger text-uppercase m-0"
              >
                {{ item.texto }}
              </h1>
            </div>
          </div>
        </router-link>
        <div
          class="grg_container_redes grg_conatiner_redes_movil"
          v-if="grg_var_view_port_md"
        >
          <span class="grg_font_family_rb grg_text_extra_small text-black"
            >¡Síguenos!</span
          >
          <ul>
            <li v-for="red_social of redesSocialesActivas" :key="red_social.id">
              <a target="_blank" :href="red_social.link">
                <img
                  class="img-fluid"
                  :src="obtenerIconoRedSocial(red_social.icono_reemplazo)"
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <div
      @click="mostrarMenu()"
      class="fixed-top h-100 w-100"
      v-if="show"
      style="z_index: 1023"
    ></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Navbar",
  props: {
    color_rgb: {
      type: String,
      default: "#000000",
    },
    trasparente: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      redes_sociales: [
        {
          id: 1,
          activo: 1,
          icono: "instagram_blanco.webp",
          icono_reemplazo: "instagram_negro.webp",
          link: "https://www.instagram.com/grgoficial/?hl=es",
        },
        {
          id: 2,
          activo: 1,
          icono: "facebook_blanco.webp",
          icono_reemplazo: "facebook_negro.webp",
          link: "https://www.facebook.com/GRGTalento",
        },
        {
          id: 3,
          activo: 0,
          icono: "twitter_blanco.webp",
          icono_reemplazo: "twitter_negro.webp",
          link: "",
        },
        {
          id: 4,
          activo: 1,
          icono: "youtube_blanco.webp",
          icono_reemplazo: "youtube_negro.webp",
          link: "https://www.youtube.com/channel/UCgIQWMTHVjh8WrGvVgalFqg",
        },
        {
          id: 5,
          activo: 0,
          icono: "whatsapp_blanco.webp",
          icono_reemplazo: "whatsapp_negro.webp",
          link: "http://www.facebook.com.mx/",
        },
        {
          id: 6,
          activo: 1,
          icono: "linkeding_blanco.webp",
          icono_reemplazo: "linkeding_negro.webp",
          //link: "https://www.linkedin.com/company/restaurantes-toks/posts/?feedView=all&viewAsMember=true",
          link: "https://www.linkedin.com/company/gruporestauranterogigante/posts/?feedView=all&viewAsMember=true",
                 
        },
      ],
      menu: [
        { id: 1, activo: 1, link: "/quienes-somos", texto: "¿Quiénes somos?" },
        { id: 2, activo: 1, link: "/nuestras-marcas", texto: "Nuestras Marcas" },
        {
          id: 3,
          activo: 1,
          link: "/responsabilidad-social",
          texto: "Responsabilidad Social",
        },
        { id: 4, activo: 1, link: "/nuestra-gente", texto: "Nuestra Gente" },
        { id: 5, activo: 1, link: "/cedis", texto: "CEDIS" },
        { id: 6, activo: 1, link: "/prensa", texto: "Prensa" },
        { id: 7, activo: 1, link: "/noticias", texto: "Noticias" },
        { id: 8, activo: 1, link: "/contacto", texto: "Contacto" },
      ],
    };
  },
  methods: {
    mostrarMenu() {
      this.show = !this.show;
    },
    obtenerIconoRedSocial(icono) {
      if (icono != "") {
        return require("@/assets/img/redes_sociales/" + icono);
      }
    },
  },
  computed: {
    ...mapState([
      "grg_var_scroll",
      "grg_var_view_port_md",
      "grg_var_transparencia",
    ]),
    redesSocialesActivas() {
      var redes_sociales_activas = [];
      this.redes_sociales.forEach((element) => {
        if (element.activo != 0) {
          redes_sociales_activas.push(element);
        }
      });
      return redes_sociales_activas;
    },
    itemsMenuActivos() {
      var menu_activos = [];
      this.menu.forEach((element) => {
        if (element.activo != 0) {
          menu_activos.push(element);
        }
      });
      return menu_activos;
    },
  },
};
</script>

<style scoped>
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para foormatear el menu establecido por bootstrap  */
.grg_modulo_header button,
.grg_modulo_menu button {
  border: none;
  position: absolute;
}
.grg_modulo_header button {
  right: 0;
}
.grg_modulo_header .navbar-toggler-icon,
.grg_modulo_menu .navbar-toggler-icon {
  background-image: none !important;
}
.navbar {
  flex-wrap: inherit;
}
::-webkit-scrollbar {
  display: none;
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Estilo para foormatear el menu establecido por bootstrap  */

.grg_container_color {
  background-color: var(--background_color);
  transition: background-color 0.3s cubic-bezier(0.16, 1, 0.3, 1);
}
.grg_container_color_transparente {
  background-color: var(--background_color_transparente);
  /* background: linear-gradient(
180deg
, #000000b5 200%, #00000091 -47%);
    box-shadow: 0px 20px 35px 50px #000000b5; */
}
.grg_container_color_scroll {
  background-color: var(--background_color) !important;
  box-shadow: none;
}
.grg_container_redes {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 75%;
  flex-direction: column;
}
.grg_container_logo {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
}
.grg_img_logo {
  width: 280px;
  transition: width 0.3s ease;
}
.grg_img_logo_scroll {
  width: 200px;
}
ul {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
ul img {
  padding-right: 1rem;
  padding-top: 0.2rem;
  max-width: 45px;
  transition: transform 0.1s;
}
ul img:hover {
  transform: scale(1.2);
}

.grg_modulo_menu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1031;
}
.grg_container_menu_uno {
  cursor: pointer;
  width: 50px;
  writing-mode: vertical-lr;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 2px 0 7px -5px #000;
}
.grg_container_menu_dos {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: calc(100vw - 50px);
  width: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: inset 2px 0 7px -5px #000;
  transform: translate(100%);
  transition: transform 0.2s ease-in-out;
}
.grg_show_menu {
  transform: translate(0%) !important;
}
.grg_container_item_hover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1rem 1rem 2rem;
}
.grg_conatiner_redes_movil {
  padding: 2.5rem 1rem 0.4rem 1.5rem;
}
.grg_container_item {
  position: relative;
  padding: 0.3rem;
  color: #444545;
}
.grg_container_item_hover:hover .grg_container_item {
  background-color: #c0203a;
  color: white;
}
.grg_container_item_hover:hover .grg_container_item:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: block;
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  border: 1px solid #c0203a;
  z-index: -1;
}
h1 {
  position: relative;
}
[data-flechas="vertical"]:before,
[data-flechas="vertical"]:after,
[data-flechas="horizontal"]:before,
[data-flechas="horizontal"]:after {
  content: url("../assets/img/utilerias/flechas_rojas.webp");
  display: inline-block;
  position: absolute;
  transform: scale(0.7);
}
[data-flechas="vertical"]:before {
  top: -50%;
}
[data-flechas="vertical"]:after {
  bottom: -50%;
}
[data-flechas="horizontal"]:before {
  left: -45px;
  transform: scale(0.7) rotate(270deg);
}
[data-flechas="horizontal"]:after {
  right: -45px;
  transform: scale(0.7) rotate(270deg);
}
.grg_p_1 {
  padding: 1rem 0;
}

@media (min-width: 1200px) {
  .grg_container_menu_dos {
    width: 40%;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 768px) {
}
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
  .grg_container_menu_dos .navbar .grg_text_extra_big,
  .grg_container_menu_uno .grg_text_extra_big {
    font-size: 30px;
  }
  .grg_container_color {
    background-color: var(--background_color);
  }
}
@media (max-width: 767.98px) {
  .grg_container_menu_dos {
    max-width: calc(100vw - 50px);
    width: 400px;
  }
  .grg_container_item_hover {
    padding: 0.8rem 0 0 1.5rem;
  }
  .grg_container_logo {
    justify-content: center;
  }
  .grg_img_logo {
    width: 180px;
  }
  .grg_img_logo_scroll {
    width: 140px;
  }
  .grg_modulo_menu {
    transform: translate(100%);
  }
}
@media (max-width: 575.98px) {
  [data-flechas="horizontal"]:before {
    left: -30px;
    transform: scale(0.7) rotate(270deg);
  }
  [data-flechas="horizontal"]:after {
    right: -30px;
    transform: scale(0.7) rotate(270deg);
  }
}
</style>